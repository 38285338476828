import { ReqQualifierServiceService } from './../../../service/req-qualifier-service.service';
import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from "@angular/router"
import { Location } from '@angular/common';

export class Message {
  constructor() {}
}

@Component({
  selector: 'app-req-form-data',
  templateUrl: './req-form-data.component.html',
  styleUrls: ['./req-form-data.component.scss'],
})
export class ReqFormDataComponent implements OnInit {
  lines: any;

  resizeTextarea(event: Event): void {
    const textarea = event.target as HTMLTextAreaElement;
    textarea.style.height = "auto";
    if( this.myInputField.nativeElement.value.includes('\n') ) {
      textarea.style.height = textarea.scrollHeight + 'px';
    } else {
      textarea.style.height = '24px';
    }
  }

  // resizeTextarea(event: Event): void {
  //   const textarea = event.target as HTMLTextAreaElement;
  //   textarea.style.height = "auto";
  //   if( this.userMessage.includes('\n') ) {
  //     textarea.style.height = textarea.scrollHeight + "px";
  //   } else {
  //     textarea.style.height = "24px";
  //   }
  // }

  @ViewChild('myIframe') myIframe!: ElementRef;
  @ViewChild('scrollMe') private myScrollContainer: any;
  $: any;

  sessionId: any;
  selectedQualifier: string = '';
  dataSet: any;
  formSubmitted: boolean = false;
  reQualifierForm!: FormGroup;
  selectedgeneratorData: string | null = '';
  showLoader: boolean = false;
  showCopyTick: boolean = false;
  limit: number = 10;
  invalidFileError: string = '';
  isSearchFormValid: boolean = false;
  noInput: boolean = true;
  history: any = {};
  promptErrMsg: boolean = false;

  @ViewChild("myinput") myInputField!: ElementRef;

  constructor(
    public formBuilder: FormBuilder,
    public reqQualifierService: ReqQualifierServiceService,
    private router: Router,
    private location: Location
  ) {
    this.selectedgeneratorData = this.reqQualifierService.selectedGeneratorData;
    this.reQualifierForm = this.formBuilder.group({
      reqQualifierSearch: ['', Validators.required],
    });
  }

  showResultOptions: any = [
    { key: 10, text: 'Show 10 Results' },
    { key: 20, text: 'Show 20 Results' },
    { key: 30, text: 'Show 30 Results' },
  ];

  titles: any = [
    {
      key: 'idea',
      text: 'feature'
    },
    {
      key: 'feature',
      text: 'story'
    },
    {
      key: 'story',
      text: 'acceptance_criteria'
    },
    {
      key: 'acceptance_criteria',
      text: 'test_scenario'
    },
    {
      key: 'test_scenario',
      text: 'requirement_granularity'
    },
  ];

  ngOnInit() {
    this.reqQualifierService.setSelectedQualifier(
      window.location.pathname.split('/').filter( ( ele:any ) => ele )[1]
    );
    /** This is subscription to clear history */
    this.reqQualifierService.resetHistory$.subscribe(() => {
      this.history = {};
    });

    /** This is subscription called every time we
     * click on any generator from right sidebar
     */
    this.reqQualifierService.methodCalled$.subscribe((receivedSlug) => {
      /** Clear existing response */
      this.dataSet = null;
      /** Clear current input form text area */
      this.reQualifierForm.controls['reqQualifierSearch'].setValue('');
      /** Set limit to 10 by default */
      this.limit = 10;
      /** Set autofocus on tex area */
      this.setAutofocusOnChatBox();

      this.setHistory(receivedSlug);
    });
  }

  setHistory(slug: string) {
    const historyObj = this.history;
    if(historyObj[slug]) {
      const dataObj = historyObj[slug];
      this.reQualifierForm.controls['reqQualifierSearch'].setValue(dataObj.key);
      this.dataSet = dataObj.value;
    }
  }

  ngAfterViewInit() {
    this.setAutofocusOnChatBox();
  }

  setSearch() {
    if( this.getCurrentSlug() !== 'requirement_granularity' ) {
      if( this.myInputField.nativeElement.value.trim() ) {
        const currentPrompt = this.myInputField.nativeElement.value.trim();
        localStorage.setItem('currentIdea', currentPrompt);
        this.getData( this.limit );
        this.promptErrMsg = false;
      } else {
        this.promptErrMsg = true;
      }
    }
    // const slugs = ['feature', 'story', 'acceptance_criteria', 'test_scenario'];
    // if( slugs.includes( this.getCurrentSlug() ) ) {
    //   this.reQualifierForm.controls['reqQualifierSearch'].setValue( localStorage.getItem('currentIdea') );
    // }
    // if( this.reQualifierForm.value.reqQualifierSearch ) {
    //   this.isSearchFormValid = true;
    // } else {
    //   this.isSearchFormValid = false;
    // }
    // localStorage.setItem('currentIdea', this.reQualifierForm.value.reqQualifierSearch);
    // this.getData( this.limit );
  }

  getData(limit = 10) {
    this.showLoader = true;
    this.dataSet = null;
    this.formSubmitted = true;

    let currentSearch = localStorage.getItem('currentIdea');
    if (currentSearch ) {
      const data = {
        prompt: this.getCurrentSlug() === 'requirement_granularity' ? JSON.parse(currentSearch) : currentSearch,
        key: this.reqQualifierService.selectedQualifier,
        token: localStorage.getItem("token"),
        userEmail: localStorage.getItem("userEmail"),
        username: localStorage.getItem("username"),
        limit: limit
      };
      this.reqQualifierService.postRequirementGeneratorInfo( data ).subscribe(
        (res) => {
          if(res.status === 'success') {
            if( this.getCurrentSlug() !== 'requirement_granularity' ) {
              this.myInputField.nativeElement.value = currentSearch;
            }
      
            let outPut = [];
            let testSet: any  = [];

            if( this.reqQualifierService.selectedQualifier === 'test_scenario' ) {
              outPut = res.output.trim().split(/(Test Scenario)\s\d+:\s/).filter( (ele: any) => ( ele && ele !== 'Test Scenario' ) );
              if( outPut.length > 1 ) {
                let testSet: any[] = [];
                outPut.forEach( ( value: any ) => {
                  value = value.replaceAll(':\n\n', '\n');
                  testSet.push( value.trim() );
                } );
                this.dataSet = testSet;
                this.showLoader = false;
                this.history[this.getCurrentSlug()] = {
                  key: currentSearch,
                  value: this.dataSet,
                  level: this.getLevel( this.getCurrentSlug() )
                }
                this.updateHistory(
                  this.getLevel( this.getCurrentSlug() )
                );
              } else {
                console.log('Response received in invalid format. Automatic regeneration start.');
                this.isSearchFormValid = true;
                this.getData();
              }
            } else if( this.reqQualifierService.selectedQualifier === 'requirement_granularity' ) {
              let output = res.output.trim().split('\n\n')[1].split('\n');

              let i = 0;
              let finalArr: { summary: string; requirements: string; score: string; }[] = [];
              output.forEach( ( value: string ) => {
                if( i >= 2 ) {
                  let tempObj = {
                    summary: '',
                    requirements: '',
                    score: '',
                  };
                  value.split(' | ').forEach( ( value, index, array ) => {
                    tempObj.summary = array[0].replace('|', '').trim();
                    tempObj.requirements = array[1].replace('|', '').trim();
                    tempObj.score = array[2].replace('|', '').trim();
                  });
                  finalArr.push( tempObj );
                }
                i++;
              } );
              this.dataSet = finalArr;
              this.showLoader = false;
            } else {
              outPut = res.output.replace(/\n/g, '\n\n').split('\n\n').filter( (ele: any) => ele )
              const pattern = /^[0-9]./;
              outPut.forEach( ( value: any ) => {
                if( pattern.test( value ) ) {
                  this.showLoader = true;
                  testSet.push( value );
                }
              });
              this.dataSet = testSet;
              this.showLoader = false;
              this.history[this.getCurrentSlug()] = {
                key: currentSearch,
                value: this.dataSet,
                level: this.getLevel( this.getCurrentSlug() )
              }
              this.updateHistory(
                this.getLevel( this.getCurrentSlug() )
              );
            }
            this.noInput = false;
          }
          //this.showLoader = false;
        },
        ( err ) => {
          console.log( err );
          this.showLoader = false;
        }
      )
    } else {
      this.dataSet = null;
      this.showLoader = false;
    }
  }

  updateHistory(level: any) {
    for( let item in this.history) {
      if(this.history[item].level > level) {
        this.history[item].key = '';
        this.history[item].value = '';
      }
    }
  }

  getLevel(slug: string) {
    let level = 0;
    if(slug) {
      switch(slug) {
        case 'idea':
          level = 1;
        break;
        case 'feature':
          level = 2;
        break;
        case 'story':
          level = 3;
        break;
        case 'acceptance_criteria':
          level = 4;
        break;
        case 'test_scenario':
          level = 5;
        break;
        case 'requirement_granularity':
          level = 6;
        break;
      }
    }
    return level;
  }

  copyToClipboard(el: HTMLSpanElement, counter: any, doRedirect = false) {
    if (navigator.clipboard) {

      /** remove bullet points from generated ideas */
      let currentCbText = this.trimResponse( el.innerText );
      navigator.clipboard.writeText( currentCbText ).then(
        () => {
          if (doRedirect) {
            this.copyToNext( currentCbText );
          } else {
            if( this.getCurrentSlug() !== 'test_scenario' ) {
              el.nextElementSibling!.querySelectorAll('img')[1].style.display = 'none';
              el.nextElementSibling!.querySelectorAll('img')[2].style.display = 'block';
              setTimeout( () => {
                el.nextElementSibling!.querySelectorAll('img')[1].style.display = 'block';
                el.nextElementSibling!.querySelectorAll('img')[2].style.display = 'none';
              }, 1000);
            } else {
              el.nextElementSibling!.querySelectorAll('img')[0].style.display = 'none';
              el.nextElementSibling!.querySelectorAll('img')[1].style.display = 'block';
              setTimeout( () => {
                el.nextElementSibling!.querySelectorAll('img')[0].style.display = 'block';
                el.nextElementSibling!.querySelectorAll('img')[1].style.display = 'none';
              }, 1000);

            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      console.log('Browser do not support Clipboard API');
    }
  }

  copyToNext( currentCbText: any) {
    /**
     * send next quilifire to sidebar component so that syde bar will sync immedietly
     */
    this.reqQualifierService.syncSideBar(this.getTitle(this.reqQualifierService.selectedQualifier));

    /**
     * Set next step slug to local storage
     * Because getData() method will get key from local storage
     */
    this.reqQualifierService.setSelectedQualifier( this.getTitle(this.reqQualifierService.selectedQualifier) );

    /**
     * Redirect user to next step
     */
    this.router.navigate([
      `/requirement-generator/${this.reqQualifierService.selectedQualifier}`
    ]);

    /**
     * Fill the input box with copied prompt
     */
    this.reQualifierForm.controls['reqQualifierSearch'].setValue(currentCbText);

    /**
     * Set copied prompt to localstorage as well
     * Because getData() method will get prompt from local storage
     */
    localStorage.setItem('currentIdea', currentCbText );


    /**
     * Clear the existing data from DataSet
     */
    this.dataSet = null;

    /**
     * Show loader
     */
    this.showLoader = true;

    /**
     * Again call getData method to get updated data, with currently set limit
     */
    this.getData( this.limit );

    /**
     * Update sidebar method to sync with current flow
     */
    this.reqQualifierService.callMethod( this.reqQualifierService.selectedQualifier );
  }

  trimResponse( data: any ) {
    const splittedData = data.split(/(\d+)+.\s/);
    if( splittedData.length > 1)
      return splittedData[2];
    else 
      return splittedData[0];
  }

  getTitle(routeName: string) {
    const matchedRoute = this.titles.find(
      (title: any) => title.key === routeName
    );
    return matchedRoute?.text;
  }

  onLimitChange( e: any ) {
    this.isSearchFormValid = true;
    this.limit = e.target.value;
    this.getData( this.limit );
  }

  setAutofocusOnChatBox() {
    setTimeout(() => {
      if( this.getCurrentSlug() !== 'requirement_granularity' ) {
        this.myInputField.nativeElement.focus();
      }
    }, 1000);
  }

  slugToText( slug: string ) {
    let title: string[] = [];
    slug.split('_').forEach( (value) => {
      title.push( value.charAt(0).toUpperCase() + value.substring(1) );
    });
    return title.join(' ');
  }

  getCurrentSlug() {
    return window.location.href.split('/').filter( ele => ele)[3];
  }

  onInputChange( e:any ) {
    if(this.myInputField.nativeElement.value.trim()) {
      this.promptErrMsg = false;
    } else {
      this.promptErrMsg = true;
    }
  }

  async onFileSelect( event: any ) {
    this.invalidFileError = "";
    const file = event.target.files[0];
    if( file ) {
      if( (file.size/(1024**2)) < 10 ) {
        if( file.type === 'application/vnd.ms-excel' || file.type === 'text/csv' ) {
          let content = await file.text();
          content = content.split('\n');
          content.shift();
          content = content.filter( (ele:string) => ele);

          let tempArr: { summary: string, requirements: string }[] = [];
          content.forEach( (value: string) => {
            let tempObj = {
              summary: '',
              requirements: ''
            }
            let subContent = value.split(/,(.*)/s).filter( ( ele ) => ele );
            tempObj.summary = subContent[0];
            tempObj.requirements = subContent[1];
            tempArr.push( tempObj );
          } )
          localStorage.setItem('currentIdea', JSON.stringify( tempArr ) );
          this.getData();
        } else {
          this.invalidFileError = "Invalid file type";
        }
      } else {
        this.invalidFileError = "File zise must be less then 10 MB";
      }
    }
  }

  preventNewLinewithoutShift( event: any ) {
    if(event.key === 'Enter' && !event.shiftKey) {
      this.setSearch();
      event.preventDefault();
    }
  }

}
