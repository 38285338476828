<div class="container">
    <div class="right-wrapper">

        <div class="main-wrapper">
            <div class="filter-box">
                <div class="title-bar">
                    <!-- Logo wrapper with logo and text -->
                    <div class="logo-wrapper addCursor" (click)="dashboardRedirection()">
                        <img src="../../../../assets/new-logo/new-logo.png" alt="logo" />
                        <h3>Genysys</h3>
                    </div>
                    <!-- Menu Icon -->
                    <!-- <div class="custom-menu-icon addCursor" (click)="dashboardRedirection()" title="Back">
                        <div class="menu menu-close">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div> -->
                    <img
                      class="home-icon"
                      src="../../../../assets/icons/home-icon.svg"
                      alt="Home"
                      (click)="dashboardRedirection()"
                      title="Home"
                    />
                </div>
                <div class="options-holder">
                    <div class="title-wrapper">
                      <h5>
                          <img src="../../../../assets/icons/generator.svg" alt="generator logo">
                          Generators
                      </h5>
                      <h5>
                        <a href="javascript:void(0);" (click)="clearHistory()" title="Clear search">
                          <img src="../../../../assets/icons/regenerate-icon.svg" alt="reset-session" />
                        </a>
                      </h5>
                    </div>
                    <div class="menu-container">
                        <div (click)="setQulifierName('idea')">
                            <div *ngIf="selectedQualifier === 'idea'">
                            <div class="menu-item-active">
                              <img class="menu-icon-active" loading="lazy" src="./../../../assets/icons/idea_active.svg" />
                              <div class="menu-name-active">Idea</div>
                            </div>
                            <div class="menu-item-active-bar">
                              <img src="./../../../assets/icons/menu-selected-icon.svg">
                            </div>
                          </div>
                          <div *ngIf="selectedQualifier !== 'idea'" class="menu-item">
                            <img class="menu-icon" loading="lazy" src="./../../../assets/icons/idea_inactive.svg" />
                            <div class="menu-name">Idea</div>
                          </div>
                        </div>
          
                        <div (click)="setQulifierName('feature')">
                          <div class="menu-container">
                            <div *ngIf="selectedQualifier === 'feature'">
                              <div class="menu-item-active">
                                <img class="menu-icon-active" loading="lazy" src="./../../../assets/icons/feature_active.svg" />
                                <div class="menu-name-active">Feature</div>
                              </div>
                              <div class="menu-item-active-bar">
                                <img src="./../../../assets/icons/menu-selected-icon.svg">
                              </div>
                            </div>
                          </div>
                          <div *ngIf="selectedQualifier !== 'feature'" class="menu-item">
                            <img class="menu-icon" loading="lazy" src="./../../../assets/icons/feature_inactive.svg" />
                            <div class="menu-name">Feature</div>
                          </div>
                        </div>
          
                        <div (click)="setQulifierName('story')">
                          <div class="menu-container">
                            <div *ngIf="selectedQualifier === 'story'">
                              <div class="menu-item-active">
                                <img class="menu-icon-active" loading="lazy" src="./../../../assets/icons/story_active.svg" />
                                <div class="menu-name-active">Story</div>
                              </div>
                              <div class="menu-item-active-bar">
                                <img src="./../../../assets/icons/menu-selected-icon.svg">
                              </div>
                            </div>
                          </div>
                          <div *ngIf="selectedQualifier !== 'story'" class="menu-item">
                            <img class="menu-icon" loading="lazy" src="./../../../assets/icons/story_inactive.svg" />
                            <div class="menu-name">Story</div>
                          </div>
                        </div>
          
                        <div (click)="setQulifierName('acceptance_criteria')">
                          <div class="menu-container">
                            <div *ngIf="selectedQualifier === 'acceptance_criteria'">
                              <div class="menu-item-active">
                                <img class="menu-icon-active" loading="lazy" src="./../../../assets/icons/acceptance_criteria_active.svg" />
                                <div class="menu-name-active">Acceptance Criteria</div>
                              </div>
                              <div class="menu-item-active-bar">
                                <img src="./../../../assets/icons/menu-selected-icon.svg">
                              </div>
                            </div>
                          </div>
                          <div *ngIf="selectedQualifier !== 'acceptance_criteria'" class="menu-item">
                            <img class="menu-icon" loading="lazy" src="./../../../assets/icons/acceptance_criteria_inactive.svg" />
                            <div class="menu-name">Acceptance Criteria</div>
                          </div>
                        </div>

                        <div (click)="setQulifierName('test_scenario')">
                          <div class="menu-container">
                            <div *ngIf="selectedQualifier === 'test_scenario'">
                              <div class="menu-item-active">
                                <img class="menu-icon-active" loading="lazy" src="./../../../assets/icons/test_scenario_active.svg" />
                                <div class="menu-name-active">Test Scenario</div>
                              </div>
                              <div class="menu-item-active-bar">
                                <img src="./../../../assets/icons/menu-selected-icon.svg">
                              </div>
                            </div>
                          </div>
                          <div *ngIf="selectedQualifier !== 'test_scenario'" class="menu-item">
                            <img class="menu-icon" loading="lazy" src="./../../../assets/icons/test_scenario_inactive.svg" />
                            <div class="menu-name">Test Scenario</div>
                          </div>
                        </div>  
                    </div>
                    <h5>
                      <img src="../../../../assets/icons/generator.svg" alt="generator logo">
                      Metrics
                    </h5>
                    <div class="menu-container">
                      <div class="menu-container">
                        <div (click)="setQulifierName('requirement_granularity')">
                          <div class="menu-container">
                            <div *ngIf="selectedQualifier === 'requirement_granularity'">
                              <div class="menu-item-active">
                                <img class="menu-icon-active" loading="lazy" src="./../../../assets/icons/requirement_granularity_active.svg" />
                                <div class="menu-name-active">Requirement Granularity</div>
                              </div>
                              <div class="menu-item-active-bar">
                                <img src="./../../../assets/icons/menu-selected-icon.svg">
                              </div>
                            </div>
                          </div>
                          <div *ngIf="selectedQualifier !== 'requirement_granularity'" class="menu-item">
                            <img class="menu-icon" loading="lazy" src="./../../../assets/icons/requirement_granularity_inactive.svg" />
                            <div class="menu-name">Requirement Granularity</div>
                          </div>
                        </div>
                      </div>
                    </div>

                </div>
            </div>
            <div class="action-wrapper">
                <div class="profiler">
                    <div class="avatar">
                        <span class="no-profile-pic" ng-bind="usernameInitials">{{usernameInitials}}</span>
                    </div>
                    <p>{{usernameDisplay}}</p>
                </div>
                <div class="logout-cta" (click)="logout()">
                    <img src="../../../../assets/icons/logout-icon.svg" alt="logout" title="logout" />
                </div>
            </div>
        </div>
    </div>
</div>